<template>
  <section class="invoice-preview-wrapper" v-if="result.holder">
    <b-row class="invoice-preview">
      <!-- Detail Topup -->
      <DetailTopup :result="result" />

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card v-if="result.status == 'pending'">
          <!-- Button: Approve -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="approveRejectTopup(id, 'approve')"
          >
            Approve Topup
          </b-button>

          <!-- Button: Reject -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-75"
            block
            @click="approveRejectTopup(id, 'reject')"
          >
            Reject Topup
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import DetailTopup from '@/components/Wallet/Talenta/DetailTopup.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Detail Topup`
  },
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BButton,
    DetailTopup,
  },
  directives: {
    Ripple,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true

      this.$http.get(`/admin/wallet/ads/topup/${this.id}`)
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    approveRejectTopup(uuid, type) {
      this.$http.post(`admin/wallet/ads/topup/${uuid}/${type}`)
        .then(response => {
          this.getData()
          successNotification(this, 'Success', `Topup successfully ${type}`)
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
